import React from "react"
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"


import Layout from "../components/layout"
import Wizard from "../components/wizard"
import createAccIcon from "../images/gray/4.svg"
import bottomLine from "../images/line.png"
import ErrrorHandel from "../components/ErrrorHandel";
import SEO from "../components/seo"
import MyInput from "../components/MyInput";
import ButtonLoader from "../components/buttonLoader";
import * as actionCreators from './../store/actions';
import MyInputMask from "../components/MyInputMask";
import { restrictRouteForStep } from "../core/util";

class CreateAccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            showpass: false,
            userId:null,
            oldEmail:null,
            leadId:null,
            error:null,
            editDisable:false
        };
    }

    async componentDidMount() {
        this.props.showLoader();
        //if (this.props.step2response && !this.props.step2response.userId) {
            let paramId = restrictRouteForStep(this.props.location);
            if (paramId) {
                await this.getFromDirect(paramId);
            }
            this.props.hideLoader();
        //}
        if(global.dealertoken && global.dealertoken==process.env.peak_dealer){
            this.setState({ editDisable: true });
        }
    }

    getFromDirect = async(paramId) => {
        const { saveSecondStep, showLoader, hideLoader, saveFirstStep, saveThirdStep, saveFourthStep, saveSecondStepResponse, saveThirdStepResponse } = this.props;
        showLoader();
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getPlanById'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                _id: paramId,
                old:1
            })
          })
        .then(res => res.json())
        .then((res) => {
            if(res.status == 1){
                let secondstepResponse = {
                    userId: res.data.step3.userId,
                    vehicleId: res.data.step3.vehicleId,
                    recordId: res.data.step3.recordId
                }
                //if(res.data.step2.password === 1){
                    this.setState({ showpass: true});
                //}
                this.setState({ userId: res.data.step3.userId, leadId:res.data.step4.leadId,oldEmail:res.data.step2.email });
                saveFirstStep(res.data.step1);
                saveSecondStep(res.data.step2);
                saveSecondStepResponse(secondstepResponse);
                saveThirdStep(res.data.step3);
                saveThirdStepResponse(res.data.step3);
                saveFourthStep(res.data.step4);
                hideLoader();
            }  else {
                navigate("/vehicleInfo/?q="+paramId+(global.dealertoken?'&dealertoken='+global.dealertoken:''))
            }
        });
    }

    emailChange = async(val) => {
        this.setState({ showpass: true });
    }
    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = (model) => {
        const { showButtonLoader, hideButtonLoader,showLoader, hideLoader, step2response, saveFourthStep, saveFourthStepResponse, saveSecondStep } = this.props;
        const { userId, oldEmail, leadId} = this.state;
        console.log(userId, oldEmail, leadId)
        showButtonLoader();
        showLoader();
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/saveaddress'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    _id: step2response.userId,
                    recordId: step2response.recordId,
                    firstName: model.firstName,
                    lastName: model.lastName,
                    email:model.email,
                    password: model.password?model.password:'',
                    phone: model.phone,
                    address: model.address,
                    state: model.state,
                    city: model.city,
                    zipcode : model.zipcode,
                    oldEmail:oldEmail,
                    leadId:leadId
                })
              })
            .then(res => res.json())
            .then((res) => {
                hideButtonLoader();
                hideLoader();
                if (res.status==1) {
                
                    saveFourthStep(model)
                    //Save userdata to step2 reducer
                    let userData = {
                        firstName: model.firstName,
                        lastName: model.lastName,
                        email: model.email,
                        phone: model.phone,
                        address: model.address,
                        state: model.state,
                        city: model.city,
                        zipcode : model.zipcode
                    }
                    saveSecondStep(userData);
                    saveFourthStepResponse(userData);
                    
                    if (step2response.recordId) {
                        if(global.dealertoken && global.href) this.ahrefElement.click();
                        navigate(`/securePayment/?q=${step2response.recordId}`+(global.dealertoken?'&dealertoken='+global.dealertoken:''));
                    } else {
                        navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                    }
                } else {
                    this.setState({ error: "Exist email or Wrong password" });
                }
            });

    }

    startNewQoute = () => {
        this.props.clearAllSteps();
        navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
    }

    render() {
        const { canSubmit,showpass, error, editDisable } = this.state;
        const { buttonLoader, step3Data, step2Data } = this.props;
        //console.log('--------------'+emailEdit);
        return (
            <Layout layoutType="main">
                <SEO title="Servicecontract.com - Create Account" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                {global.dealertoken?
                <a className="hideme" id="price-step5" href={global.href+"#price-step5"} ref={ahref => this.ahrefElement = ahref} target="_top">trigger</a>
                :''}
                <div className="chat-content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                        <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                            <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                                <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                                <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                            </g>
                        </g>
                    </svg>

                </div>
                <section className={global.dealertoken?'grayish':''}>
                    <Wizard></Wizard>
                    <div className="custom-container vehinfo">

                        <div className="title-container">
                            <div className="icon-image">
                                <img src={createAccIcon} alt="icon" />
                            </div>
                            <div className="title-text">
                                <h1>Create Your Account</h1>
                                <p>Creating an account gives you the option to save multiple quotes and then buy your plan.</p>
                            </div>
                        </div>

                        <div className="body-content inline">
                            <div className="left-content">
                                <div className="amount-container mb-16">
                                    <div className="amount-inner">
                                        <h3>Quote</h3>
                                        <div className="amt">
                                            <p>{step3Data.termMonth?'Payment':'Monthly Payment'}</p>
                                            <h2><sup>$</sup>{step3Data && step3Data.monthlyPayment ? step3Data.monthlyPayment : 0}</h2>
                                        </div>
                                        <div className="amt">
                                            {step3Data.termMonth?
                                            <><p>Months</p><h2>{step3Data.termMonth}</h2></>
                                            :
                                            <><p>Deductible</p><h2><sup>$</sup>{step3Data.payAtClaimTime}</h2></>
                                            }
                                        </div>
                                        <div className="amt">
                                            <p>Plan Level</p>
                                            <label className="text">{step3Data && step3Data.levelOfCoverage ? step3Data.levelOfCoverage : ''}</label>
                                        </div>
                                    </div>
                                </div>
                                {/* <button className="btn outline">START A NEW QUOTE</button> */}
                                <button type="button" onClick={() => this.startNewQoute()} className="btn outline quote-btn-2">START A NEW QUOTE</button>
                                {/* <Link to="/vehicleInfo" className="btn outline">START A NEW QUOTE</Link> */}

                            </div>
                            <div className="right-content">
                                <Formsy ref="createAccountForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                                    <ErrrorHandel formName={'createAccountForm'} self={this} />
                                    <div className="form-container-inline">
                                        <div className="form-left">
                                            <div className="form-label-group">
                                                <MyInput
                                                    name="firstName"
                                                    type="text"
                                                    id="firstName"
                                                    placeholder="First Name"
                                                    value={step2Data.firstName ? step2Data.firstName : ''}
                                                    className="form-control"
                                                    required
                                                    isLabelRequired={true}
                                                    validations={{minLength: 3,maxLength:25}}
                                                    validationError="Please enter a valid firstname."
                                                />
                                            </div>

                                        </div>
                                        <div className="form-right">
                                            <div className="form-label-group">
                                                <MyInput
                                                    name="lastName"
                                                    type="text"
                                                    id="lastName"
                                                    placeholder="Last Name"
                                                    value={step2Data.lastName ? step2Data.lastName : ''}
                                                    className="form-control"
                                                    required
                                                    isLabelRequired={true}
                                                    validations={{minLength: 3,maxLength:25}}
                                                    validationError="Please enter a valid lastname."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-container-inline">
                                        <div className="form-left">
                                            <div className="form-label-group">
                                                <MyInput
                                                    name="address"
                                                    type="text"
                                                    id="address"
                                                    placeholder="Street Address"
                                                    value={step2Data.address ? step2Data.address : ''}
                                                    className="form-control"
                                                    validations={{minLength: 3,maxLength:40}}
                                                    validationError="Please enter a valid address."
                                                    required
                                                    isLabelRequired={true}
                                                />
                                            </div>

                                        </div>
                                        <div className="form-right">
                                            <div className="form-label-group">
                                                <MyInput
                                                    name="city"
                                                    id="city"
                                                    type="text"
                                                    placeholder="City"
                                                    value={step2Data.city ? step2Data.city : ''}
                                                    className="form-control"
                                                    required
                                                    isLabelRequired={true}
                                                    validations={{minLength: 3,maxLength:30}}
                                                    validationError="Please enter a valid city."
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-container-inline">
                                        <div className="form-left">
                                            <div className="form-label-group">
                                            <MyInput
                                                    name="state"
                                                    id="state"
                                                    type="text"
                                                    placeholder="State"
                                                    value={step2Data.state ? step2Data.state : ''}
                                                    isLabelRequired={true}
                                                    disabled={true}
                                                    className="form-control"
                                                    required
                                                />
                                            </div>

                                        </div>
                                        <div className="form-right">
                                            <div className="form-label-group ">
                                                <MyInput
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    type="text"
                                                    validations="isEmail"
                                                    validationError="Please enter valid email"
                                                    value={step2Data.email ? step2Data.email : ''}
                                                    isLabelRequired={true}
                                                    className="form-control"
                                                    required
                                                    valueChanges={(e) => this.emailChange(e)}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-container-inline">
                                        <div className="form-left">
                                            <div className="form-label-group">
                                                <MyInputMask
                                                    name="phone"
                                                    id="phone"
                                                    placeholder="Phone"
                                                    mask="999-999-9999"
                                                    value={step2Data.phone ? step2Data.phone : ''}
                                                    isLabelRequired={true}
                                                    className="form-control"
                                                    required
                                                    validations={{
                                                        minLength: 12,maxLength:15
                                                    }}
                                                    validationError="Please enter a valid mobile number."
                                                />
                                                {/* <br />
                                                <small className="info-text">Providing your number here will be used to support your account after you purchase.</small> */}
                                            </div>

                                        </div>

                                        <div className="form-right">
                                            <div className="form-label-group">
                                                <MyInputMask
                                                    name="zipcode"
                                                    id="zipcode"
                                                    placeholder="Zipcode"
                                                    mask="99999"
                                                    value={step2Data.zipcode ? step2Data.zipcode : ''}
                                                    isLabelRequired={true}
                                                    className="form-control"
                                                    validations={{
                                                        minLength: 5
                                                    }}
                                                    validationError="Please enter a valid zipcode."
                                                    required
                                                />
                                            </div>

                                        </div>
                                        
                                    </div>
                                    <div className="form-container-inline">
                                        {showpass?
                                        <div className="form-left">
                                                <div className="form-label-group">
                                                    <MyInput
                                                        name="password"
                                                        id="password"
                                                        type="password"
                                                        placeholder="Password"
                                                        value={''}
                                                        className="form-control"
                                                        isLabelRequired={true}
                                                        validations={{
                                                            minLength: 8
                                                        }}
                                                        validationError="Password must be 8 characters minimum."
                                                        required
                                                    />
                                                    {/* <small className="info-text">Password must be 8 characters minimum.</small> */}
                                                </div>
                                        </div>
                                        :''}
                                    </div>
                                    <div className="error-txt">{error}</div>
                                    <div className="btn-container">
                                        {/* <button className="btn">Continue</button> */}
                                        {/* <Link to="/securePayment" className="btn">Continue</Link> */}
                                        <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                                            <ButtonLoader isLoading={buttonLoader} label={'Continue'} />
                                        </button>
                                    </div>

                                </Formsy>
                                <div className="txtright">* Field is required</div>
                            </div>

                        </div>
                    </div>
                    {global.dealertoken?'':
                    <div className="vehicle-bottom-line bottom-line">
                        <img src={bottomLine} alt="line" />
                    </div>
                    }
                </section>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
        step2response: state.register.step2response,
        step4: state.register.step4,
        step2Data: state.register.step2,
        step3Data: state.register.step3,
        step4response: state.register.step4response,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader()),
        saveFourthStep: (formData) => dispatch(actionCreators.saveFourthStep(formData)),
        saveSecondStep: (formData) => dispatch(actionCreators.saveSecondStep(formData)),
        saveFourthStepResponse: (response) => dispatch(actionCreators.saveFourthStepResponse(response)),
        saveThirdStep: (formData) => dispatch(actionCreators.saveThirdStep(formData)),
        saveFirstStep: (formData) => dispatch(actionCreators.saveFirstStep(formData)),
        saveSecondStepResponse: (response) => dispatch(actionCreators.saveSecondStepResponse(response)),
        saveThirdStepResponse: (response) => dispatch(actionCreators.saveThirdStepResponse(response)),
        clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountPage);