import React from "react"
import loader from "../images/loader.svg"

const ButtonLoader = ({ isLoading, label }) => (
  <>
    {
      isLoading &&
      // <div className="loader">Loading...</div>
      <div className="loader-container">
        <img className="loader-spin" src={loader} alt="" />
      </div>
      
    }{label}
  </>
)

export default ButtonLoader
