import React, { Component } from "react"
import { Link } from "gatsby";
import { WIZARD_STEPS } from "./constant";
import { connect } from "react-redux"

import leftarrow from "../images/left-arrow.svg"

class Wizard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
        };
    }

    componentDidMount() {
        for (let key in WIZARD_STEPS) {
            if (window.location.pathname.includes(key)) {
                this.setState({ currentStep: WIZARD_STEPS[key] });
            }
        }
        // this.setState({ currentStep: WIZARD_STEPS[window.location.pathname.replace(/[^a-zA-Z ]/g, "")] })  // remove special character from pathname
    }

    render() {
        const { currentStep } = this.state;
        const { step2response } = this.props;
        // const backnavigation = currentStep > 1 && WIZARD_STEPS_NUMBER_WISE[currentStep - 1];
        let backnavigation = 'vehicleInfo';
        if (currentStep > 1) {
            Object.keys(WIZARD_STEPS).forEach((key) => {
                if (WIZARD_STEPS[key] === currentStep - 1) {
                    backnavigation = key;
                }
            });
        }
        let recordId = '';
        if (step2response && step2response.recordId) {
            recordId = `/?q=${step2response.recordId}`;
        }
        if(recordId)
            recordId += (global.dealertoken?'&dealertoken='+global.dealertoken:'');
        else
            recordId = (global.dealertoken?'/?dealertoken='+global.dealertoken:'');

        return (
            <div className="wizrd-container">
                {
                    currentStep !== 1 && currentStep !== 6 &&
                    <Link to={`/${backnavigation}${recordId}`} state={{ isFromWizard: true }} >
                        <span className="left-arrow"><img src={leftarrow} alt={'leftarrow'} /></span>
                    </Link>
                }
                <ul>
                    <li className={currentStep === 1 ? 'active' : currentStep !== 6 ? 'complete' : ''} >
                        {currentStep !== 6 ?
                            <Link to={`/vehicleInfo${recordId}`} state={{ isFromWizard: true }} >
                                <label>Vehicle Info</label>
                                <span>1</span>
                            </Link>
                            :
                            <>
                                <label>Vehicle Info</label>
                                <span>1</span>
                            </>
                        }
                    </li>
                    <li className={currentStep === 2 ? 'active' : currentStep > 2 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 2 && currentStep !== 6
                                ?
                                <Link to={`/yourInfo${recordId}`} state={{ isFromWizard: true }} >
                                    <label>Your Info</label>
                                    <span>2</span>
                                </Link>
                                :
                                <>
                                    <label>Your Info</label>
                                    <span>2</span>
                                </>
                        }

                    </li>
                    <li className={currentStep === 3 ? 'active' : currentStep > 3 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 3 && currentStep !== 6
                                ?
                                <Link to={`/customizePlan${recordId}`} state={{ isFromWizard: true }} >
                                    <label>Customize Plan</label>
                                    <span>3</span>
                                </Link>
                                :
                                <>
                                    <label>Customize Plan</label>
                                    <span>3</span>
                                </>
                        }

                    </li>
                    <li className={currentStep === 4 ? 'active' : currentStep > 4 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 4 && currentStep !== 6
                                ?
                                <Link to={`/createAccount${recordId}`} state={{ isFromWizard: true }} >
                                    <label>Create Account</label>
                                    <span>4</span>
                                </Link>
                                :
                                <>
                                    <label>Create Account</label>
                                    <span>4</span>
                                </>
                        }

                    </li>
                    <li className={currentStep === 5 ? 'active' : currentStep > 5 && currentStep !== 6 ? 'complete' : ''}>
                        {
                            currentStep > 5 && currentStep !== 6
                                ?
                                <Link to={`/securePayment${recordId}`} state={{ isFromWizard: true }} >
                                    <label>Payment Info</label>
                                    <span>5</span>
                                </Link>
                                :
                                <>
                                    <label>Payment Info</label>
                                    <span>5</span>
                                </>
                        }

                    </li>
                    <li className={currentStep === 6 ? 'active' : currentStep > 6 ? 'complete' : ''}>
                        <label>Confirm</label>
                        <span>6</span>
                    </li>
                </ul>
                <span className="line"></span>
            </div >
        );
    }
}


const mapStateToProps = state => {
    return {
        step2response: state.register.step2response
    }
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
